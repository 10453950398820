<template>
  <BarGraphic :options="chartOptions" :id="id" :data="chartData" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BarChartEmployees",
  components: {
    BarGraphic: () => import("@/components/ui/graphs/BarGraphic"),
  },
  computed: {
    chartData() {
      return {
        labels: this.$store.state.stats.lists.salesByEmployee.map(
          (e) => e.tattooer.user.last_name
        ),

        datasets: [
          {
            backgroundColor: this.$store.state.stats.lists.salesByEmployee.map(
              (e) => e.tattooer.color
            ),
            data: this.$store.state.stats.lists.salesByEmployee.map(
              (e) => e.sales_quantity
            ),
          },
        ],
      };
    },
  },
  mounted() {},
  methods: {
    ...mapGetters("app", ["randomColor"]),
  },
  data() {
    return {
      id: "employeeGraph",
      chartOptions: {
        responsive: true,
        indexAxis: "y",
        maxBarThickness: 20,
        minBarThickness: 10,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
</script>
<style></style>
